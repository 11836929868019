import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'reactstrap';
import { apiService } from '../../helpers/service.api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

const SearchBox = () => {
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    apiService.searchFile(searchValue).then(result => {
      if (result.length > 0) {
        history.push(`/results/${result[0].sha256}`);
      } else {
        toast.info(`This file not found.`);
      }
    });
  };

  return (
    <Form inline className="search-box" onSubmit={handleSubmit}>
      <Input
        type="search"
        value={searchValue}
        onChange={({ target }) => setSearchValue(target.value)}
        placeholder="Search by hash (SHA256, SHA1, MD5 etc.) ..."
        aria-label="Search"
        className="rounded-pill search-input"
      />
      <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
    </Form>
  );
};

export default SearchBox;
