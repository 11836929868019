import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import DashboardLayout from './DashboardLayout';
import ErrorLayout from './ErrorLayout';

import loadable from '@loadable/component';
const Landing = loadable(() => import('../components/landing/Landing'));
//{import AuthenticatedRoute from './AuthenticatedRoute';}
const AuthBasicLayout = loadable(() => import('./AuthBasicLayout'));

const Layout = () => {
  useEffect(() => {
    AuthBasicLayout.preload();
  }, []);

  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/landing" exact component={Landing} />
        <Route path="/auth" component={AuthBasicLayout} />
        <Route path="/errors" component={ErrorLayout} />
        <Route component={DashboardLayout} />
        {/*<AuthenticatedRoute component={DashboardLayout} />*/}
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
