export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/',
      name: 'Dashboard',
      exact: true
    },
    { to: '/landing', name: 'Landing' }
  ]
};

export default [homeRoutes];
