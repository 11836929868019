import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import Dashboard from '../components/dashboard/Dashboard';
import NavbarVertical from '../components/navbar/NavbarVertical';
import Footer from '../components/footer/Footer';
import loadable from '@loadable/component';
import AppContext from '../context/Context';
import SidePanelModal from '../components/side-panel/SidePanelModal';
import { getPageName } from '../helpers/utils';
import NavbarTop from '../components/navbar/NavbarTop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const DashboardRoutes = loadable(() => import('./DashboardRoutes'));
const AskQuestionForm = ({ toggleModal, isOpen }) => {
  // State
  const [name, setName] = useState('');
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();
    var data = {};
    data.name = name;
    data.email = email;
    data.subject = 'Borabay Feedback Notification';
    data.message = `
     Name: ${name}
     Company Email: ${email}
     Feedback: ${feedback}
     `;

    fetch('https://qwjc6rkqbg.execute-api.us-east-1.amazonaws.com/FeedbackFormHandler', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        toast.success(`Your feedback has been received. Thanks for your interest.`);
        setFeedback('');
        setName('');
        setEmail('');
      })
      .catch(_ => toast.error(`Your feedback could not be received.`));
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader>Send Feedback</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input id="name" value={name} onChange={({ target }) => setName(target.value)} />
          </FormGroup>
          <FormGroup>
            <Label for="emailModal">Email Address</Label>
            <Input id="emailModal" value={email} onChange={({ target }) => setEmail(target.value)} type="email" />
          </FormGroup>
          <FormGroup>
            <Label for="feedback">Feedback</Label>
            <Input type="textarea" value={feedback} onChange={({ target }) => setFeedback(target.value)} id="feedback" rows="4" />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" onClick={toggleModal}>
            Close
          </Button>
          <Button color="primary" size="sm" onClick={handleSubmit}>
            Send
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const DashboardLayout = ({ location }) => {
  const { isFluid, isTopNav, navbarStyle } = useContext(AppContext);
  const isKanban = getPageName('kanban');
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid || isKanban ? 'container-fluid ' : 'container'}>
      {!isTopNav && <NavbarVertical isKanban={isKanban} navbarStyle={navbarStyle} />}
      <div className="content min-vh-100 d-flex flex-column">
        <NavbarTop />
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <DashboardRoutes />
        </Switch>
        {!isKanban && <Footer />}
      </div>
      <SidePanelModal path={location.pathname} />
      <Button style={{ position: 'fixed', right: '4px', bottom: '4px' }} color="falcon-primary" onClick={toggleModal}>
        <FontAwesomeIcon icon="mail-bulk" className="mr-2" />
        Send Feedback
      </Button>
      <AskQuestionForm toggleModal={toggleModal} isOpen={isOpen} />
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };
export default DashboardLayout;
