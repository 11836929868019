import axios from 'axios';
import { ApiUrl } from '../config';
import { toast } from 'react-toastify';

const service = axios.create({
  baseURL: ApiUrl,
  //timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});
service.CancelToken = axios.CancelToken;
service.isCancel = axios.isCancel;
service.interceptors.response.use(
  response => response,
  error => {
    if (!error.response) {
      toast.error(`API (${ApiUrl}) is not accessible.`, { autoClose: false });
    }
    return Promise.reject({ ...error });
  }
);

export default service;
