import service from './service';

export const apiService = {
  // Upload File
  uploadFile,

  // Last Analysis
  getLastAnalysis,

  // Analysis Details
  getFileById,
  getFileInfoById,
  getMacroSignatureInfoById,
  getRtfObjectInfoById,
  getRtfObjectDetailsInfoByRtfObjectId,
  getYaraInfoById,
  getYaraMatchInfoByYaraId,
  getHashInfoById,
  getExifInfoById,
  getBinwalkInfoById,
  getMacroInfoById,
  getDDEInfoById,
  getFileIdByHash,
  getPreviewById,
  getMoganInfoById,
  getMalwareBazaarInfoById,

  // Search File
  searchFile
};

/* ---------------------------
Upload File
--------------------------- */
async function uploadFile(file) {
  try {
    const blob = await fetch(file.base64).then(res => res.blob());
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('name', file.path);
    let response = await service.put('/upload/', formData);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

/* ---------------------------
Last Analysis
--------------------------- */
async function getLastAnalysis() {
  try {
    let response = await service.get('/last/');
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

/* ---------------------------
Analysis Details
--------------------------- */

async function getFileById(id) {
  try {
    let response = await service.get(`/file/${id}/`);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return null;
  }
}

async function getFileInfoById(id) {
  let result = await getInfoById('file_info', id);
  if (result.length > 0) {
    return result[0];
  } else {
    return null;
  }
}

async function getRtfObjectInfoById(id) {
  return getInfoById('rtf_object', id);
}

async function getRtfObjectDetailsInfoByRtfObjectId(id) {
  try {
    let response = await service.get(`/rtf_object_detail/?rtf_object=${id}`);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

async function getYaraInfoById(id) {
  return getInfoById('yara', id);
}

async function getYaraMatchInfoByYaraId(id) {
  try {
    let response = await service.get(`/match/?yara=${id}`);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

async function getHashInfoById(id) {
  let result = await getInfoById('hash', id);
  if (result.length > 0) {
    return result[0];
  } else {
    return null;
  }
}

async function getExifInfoById(id) {
  return getInfoById('exif', id);
}

async function getBinwalkInfoById(id) {
  return getInfoById('binwalk', id);
}

async function getMacroSignatureInfoById(id) {
  return getInfoById('macro_signature', id);
}

async function getMacroInfoById(id) {
  return getInfoById('macro', id);
}

async function getDDEInfoById(id) {
  return getInfoById('dde', id);
}

async function getMoganInfoById(id) {
  return getInfoById('mogan', id);
}

async function getMalwareBazaarInfoById(id){
  return getInfoById('malwarebazaar', id)
}

async function getInfoById(path, id) {
  try {
    let response = await service.get(`/${path}/?file=${id}`);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

async function getFileIdByHash(hash, type = 'sha256') {
  try {
    let response = await service.get(`/hash/?${type}=${hash}`);
    if (response.data.length > 0) {
      return response.data[0].file_id;
    }
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
  }
  return null;
}

async function getPreviewById(id) {
  try {
    let response = await service.get(`/preview/?file=${id}`);
    return response.data;
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
    return [];
  }
}

/* ---------------------------
Search File
--------------------------- */
async function searchFile(hash) {
  try {
    hash = encodeURIComponent(hash);
    let response = await service.get(`/hash/?sha512=${hash}`);
    if (response.data.length > 0) {
      return response.data;
    }
    response = await service.get(`/hash/?sha256=${hash}`);
    if (response.data.length > 0) {
      return response.data;
    }
    response = await service.get(`/hash/?sha1=${hash}`);
    if (response.data.length > 0) {
      return response.data;
    }
    response = await service.get(`/hash/?md5=${hash}`);
    if (response.data.length > 0) {
      return response.data;
    }
  } catch (error) {
    console.log('Error: ', JSON.stringify(error, null, 4));
  }
  return [];
}
