import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Media, Card, CardBody, Row } from 'reactstrap';
import FalconDropzone from '../common/FalconDropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { apiService } from '../../helpers/service.api';
import { toast } from 'react-toastify';
import Loader from '../common/Loader';

const Dashboard = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Row className="flex-center" style={{ minHeight: 'calc(100vh - 130px)' }}>
          <Card className="h-100 w-100">
            <CardBody className="p-5">
              <p className="lead text-800 text-sans-serif font-weight-semi-bold">New analysis</p>
              <p className="lead text-400 fs--1 text-sans-serif">Office document files (.doc, .xls, .ppt etc.) analysis.</p>
              <hr />
              <Media className="flex-center pb-3 d-block d-md-flex text-center">
                <Media body>
                  <FalconDropzone
                    onChange={files => {
                      setLoading(true)
                      files.map(async file => {
                        const result = await apiService.uploadFile(file);
                        if (result !== null) {
                          history.push(`/results/${result.sha256}`);
                        } else {
                          toast.error(`File upload is not successful.`);
                        }
                      });
                    }}
                    className="py-6"
                    multiple={false}
                    placeholder={
                      <Fragment>
                        <div className="fs-8">
                          <FontAwesomeIcon icon="file-word" className="mr-2" />
                          <FontAwesomeIcon icon="file-excel" className="mr-2" />
                          <FontAwesomeIcon icon="file-powerpoint" />
                        </div>
                        <Media className="fs-0 mx-auto d-inline-flex align-items-center">
                          <Media>
                            <p className="fs-0 mb-0 text-700">Drag &#x26; Drop a file or click to upload.</p>
                          </Media>
                        </Media>
                        <p className="mb-0 w-75 mx-auto text-500">(Curently only work for office documents.)</p>
                      </Fragment>
                    }
                  />
                </Media>
              </Media>
            </CardBody>
          </Card>
        </Row>
      )}
    </Fragment>
  );
};

export default Dashboard;
