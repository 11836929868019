import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import SettingsAnimatedIcon from './SettingsAnimatedIcon';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TopNavRightSideNavItem = () => {
  return (
    <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
      <NavItem>
        <NavLink className="nav-link ml-3" tag={Link} to="/">
          <FontAwesomeIcon icon="fire" className="mr-2 fs-1" />
          New Analysis
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link ml-3" tag={Link} to="/last">
          <FontAwesomeIcon icon="history" className="mr-2 fs-1" />
          Last 30 Analysis
        </NavLink>
      </NavItem>

      <NavItem className="ml-2">
        <SettingsAnimatedIcon />
      </NavItem>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
