import React, { useContext, useState } from 'react';
import { Collapse, Navbar, NavItem, Nav, NavbarBrand } from 'reactstrap';
import classNames from 'classnames';
import AppContext from '../../context/Context';
import SearchBox from './SearchBox';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import { breakpoints } from '../../helpers/utils';
import { Link } from 'react-router-dom';

const NavbarTop = () => {
  const { showBurgerMenu, setShowBurgerMenu, isTopNav } = useContext(AppContext);
  const [navbarCollapsed, setNavbarCollapsed] = useState(false);

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
  };

  return (
    <Navbar
      light
      className="navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit mb-3"
      expand={isTopNav ? topNavbarBreakpoint : navbarBreakPoint}
    >
      <div
        className={classNames('toggle-icon-wrapper mr-md-3 mr-2', {
          'd-lg-none': isTopNav,
          [`d-${navbarBreakPoint}-none`]: !isTopNav
        })}
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex align-item-center justify-content-center "
          onClick={() => {
            !isTopNav ? setShowBurgerMenu(!showBurgerMenu) : setNavbarCollapsed(!navbarCollapsed);
          }}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </div>
      <NavbarBrand tag={Link} to="/">
        Borabay
      </NavbarBrand>
      <Collapse navbar isOpen={navbarCollapsed} className="scrollbar">
        <Nav navbar>
          <NavItem onClick={handleSetNavbarCollapsed}>
            <SearchBox />
          </NavItem>
        </Nav>
      </Collapse>
      <TopNavRightSideNavItem />
    </Navbar>
  );
};

export default NavbarTop;
